import { Select, Stack, Checkbox, Divider, FormControlLabel, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { getObjKeysTrue } from '.';
import { withStyles } from '@mui/styles';
import { emphasize, Theme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';

const CustomSelect = withStyles(({ palette, spacing }: Theme) => ({
	// Mimic the Chip appearance
	root: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 0,
			padding: 0
		}
	},
	backdrop: {
		backgroundColor: 'transparent'
	},
	select: {
		border: `1px solid ${emphasize(palette.primary.main, 0.12)}`,
		borderRadius: spacing(2),
		backgroundColor: palette.background.paper,
		padding: 0,
		paddingLeft: spacing(1),
		display: 'flex',
		alignItems: 'center',
		'&:focus': {
			borderRadius: spacing(2)
		},
		'&:hover': {
			// convert color token to hex value of RGB and add transparency: 77 for 50%
			backgroundColor: `${palette.primary.main}77`
		},
		fontSize: '14px',
		color: palette.primary.main
	},
	iconOutlined: {
		color: palette.primary.main
	}
}))(Select);

export function FilterSection({ filters, filterState, updateFilterState, disabledFilter, sx = {} }) {
	const [showSelect, setShowSelect] = useState({});
	const { t } = useTranslation();
	const getLabelOrCounter = (filterItem) => {
		const count = filterItem.items?.length
			? filterItem.items.filter((item) => filterState[filterItem.filterKey]?.[item.value]).length
			: 0;
		return count ? `(${count})` : t('orders:reset_filter');
	};
	const { palette } = useTheme();

	return (
		<Stack
			id="filter-section"
			sx={{ flexDirection: 'row', alignSelf: 'flex-start', ml: 2, gap: 2, ...sx }}
		>
			{Array.isArray(filters) &&
				filters.map((filterItem) => (
					<CustomSelect
						open={showSelect[filterItem.filterKey] === true}
						onOpen={() => setShowSelect({ [filterItem.filterKey]: true })}
						onClose={() => setShowSelect({})}
						key={filterItem.filterKey}
						IconComponent={ArrowDropDownIcon}
						renderValue={() => `${filterItem.placeholder}: ${getLabelOrCounter(filterItem)}`}
						size="medium"
						displayEmpty={true}
						value={getObjKeysTrue(filterState[filterItem.filterKey])?.[0] || ''}
						disabled={disabledFilter}
						MenuProps={{
							BackdropProps: {
								style: {
									backgroundColor: 'transparent',
									backdropFilter: 'none'
								}
							}
						}}
						sx={
							getLabelOrCounter(filterItem) !== t('orders:reset_filter')
								? {
										'& .MuiSelect-outlined': {
											backgroundColor: `${palette.primary.main}cc`,
											color: palette.primary.contrastText
										},
										'& .MuiSelect-icon': {
											color: palette.primary.contrastText
										}
								  }
								: {}
						}
					>
						<CheckAll
							filterState={filterState}
							updateFilterState={updateFilterState}
							filterItem={filterItem}
							setShowSelect={setShowSelect}
						/>
						<Divider />
						{filterItem?.items?.map((item) => (
							<Stack sx={{ ml: 2, w: 1 }} key={item.label}>
								<FormControlLabel
									control={
										<Checkbox
											checked={filterState[filterItem.filterKey]?.[item.value]}
											onChange={() => updateFilterState(filterItem.filterKey, item.value)}
										/>
									}
									label={item.label}
								/>
							</Stack>
						))}
					</CustomSelect>
				))}
		</Stack>
	);
}

const CheckAll = ({
	filterState,
	updateFilterState,
	filterItem: { items, filterKey },
	setShowSelect
}) => {
	const { t } = useTranslation();
	const allChecked = items?.every((item) => filterState[filterKey]?.[item.value]);
	const allCleared = items?.every((item) => !filterState[filterKey]?.[item.value]);

	return (
		<Stack sx={{ ml: 2, w: 1 }}>
			<FormControlLabel
				control={
					<Checkbox
						checked={allChecked || allCleared}
						indeterminate={!allChecked && !allCleared}
						onChange={() => {
							updateFilterState(filterKey, '');
							setShowSelect({});
						}}
					/>
				}
				label={t('orders:reset_filter')}
			/>
		</Stack>
	);
};
