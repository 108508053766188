import makeStyles from '@mui/styles/makeStyles';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	AccountSpecificFeature,
	Feature,
	useAccountSpecificFeature,
	useFeature
} from '../../core/acl/features';
import PrimaryButton from '../../mui-custom/Button/PrimaryButton';
import {
	openPortalSession,
	startListeningForSubscriptionChanges,
	stopListeningForSubscriptionChanges
} from './actions';
import { ChargebeePlansV2 } from '../../../server/chargebee/chargebee.enums';
import { ButtonColor, SecondaryButton } from '../../mui-custom/Button/SecondaryButton';
import { UpgradeDialog } from '../UpgradeDialog/App';
import { changeSubscription } from './api';
import { showNotification } from '../../core/actions';
import { getActiveSubscription, loadAccountDetails } from '../../../app/core/admin/account/actions';
import { State } from '../../../app/core/reducers';
import { Tooltip, Typography } from '@mui/material';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	upgradeButton: {
		flex: '0 0 auto'
	}
}));

export interface Plan {
	name: ChargebeePlansV2;
	price: number;
	credits: number;
}

export function UpgradeButton({
	buttonText,
	secondary,
	plan,
	onClose
}: {
	buttonText?: string;
	secondary?: boolean;
	plan?: Plan;
	onClose?: () => void;
}) {
	const { account } = useSelector((state: State) => state.admin.account);
	const location = useLocation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();
	const { action } = qs.parse(location.search, {
		ignoreQueryPrefix: true
	});
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);
	useEffect(() => {
		dispatch(startListeningForSubscriptionChanges());
		if (action === 'upgrade') dispatch(openPortalSession(hasPricingModelV2));
		return () => {
			stopListeningForSubscriptionChanges();
		};
	}, []);

	const [disableButton, setDisableButton] = useState(false);
	const [showToolTip, setShowToolTip] = useState(false);
	const [usePaywallRedirectButton, setUsePaywallRedirectButton] = useState(false);

	const { paymentSources } = useSelector((state: State) => state.admin.account);
	const { auth } = useSelector((state: State) => state);

	useEffect(() => {
		if (!account?.activePlanUntil || !hasPricingModelV2) return;

		setShowToolTip(!paymentSources?.length && !!account?.activePlanUntil);
		// disable button in settings
		if (!paymentSources?.length && location.pathname.includes('settings/subscription')) {
			setDisableButton(true);
		} else {
			setDisableButton(false);
		}

		// in paywall use button to redirect to subscription settings
		if (!paymentSources?.length && !location.pathname.includes('settings/subscription')) {
			setUsePaywallRedirectButton(true);
		} else {
			setUsePaywallRedirectButton(false);
		}
	}, [paymentSources, location]);

	const hasChargebeeCheckout = useFeature(Feature.ChargebeeCheckout);
	const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
	const [confirmIsLoading, setConfirmIsLoading] = useState(false);
	if (!hasChargebeeCheckout) return null;
	const handleUpgradeButtonClick = () => {
		if (hasPricingModelV2) {
			setOpenUpgradeDialog(true);
			return;
		}

		dispatch(openPortalSession(hasPricingModelV2));
	};
	const handleClose = () => {
		setOpenUpgradeDialog(false);
	};
	const handleConfirm = async (planId: ChargebeePlansV2) => {
		try {
			setConfirmIsLoading(true);
			await changeSubscription({ planId, coupon_code: auth.account?.coupon?.coupon_code });
			dispatch(loadAccountDetails());
			dispatch(getActiveSubscription());
			dispatch(showNotification(t('general:subscription_successful'), 'success'));
			if (onClose) {
				onClose();
			}
			setOpenUpgradeDialog(false);
		} catch (error) {
			dispatch(showNotification(t('general:default_error'), 'error'));
			throw error;
		}
		setConfirmIsLoading(false);
	};
	const RenderUpgradeButton = () => {
		return (
			<>
				{!secondary ? (
					usePaywallRedirectButton ? (
						<Link to="/app/settings/subscription">
							<PrimaryButton
								className={classes.upgradeButton}
								sx={{
									width: '100%'
								}}
							>
								{buttonText ? buttonText : t('admin:licenses_upgrade_button')}
							</PrimaryButton>
						</Link>
					) : (
						<PrimaryButton
							onClick={handleUpgradeButtonClick}
							className={classes.upgradeButton}
							disabled={disableButton}
							sx={{
								width: '100%'
							}}
						>
							{buttonText ? buttonText : t('admin:licenses_upgrade_button')}
						</PrimaryButton>
					)
				) : (
					<SecondaryButton
						color={ButtonColor.Creative}
						size="small"
						onClick={handleUpgradeButtonClick}
						disabled={disableButton}
						sx={{
							width: '100%'
						}}
					>
						{buttonText ? buttonText : t('admin:licenses_upgrade_button')}
					</SecondaryButton>
				)}
			</>
		);
	};

	return (
		<>
			{showToolTip ? (
				<Tooltip
					title={
						<>
							<Typography
								dangerouslySetInnerHTML={{
									__html: t('admin:upgrade_button_payment_method_missing')
								}}
							></Typography>
						</>
					}
				>
					<span>
						<RenderUpgradeButton />
					</span>
				</Tooltip>
			) : (
				<RenderUpgradeButton />
			)}

			{hasPricingModelV2 && (
				<UpgradeDialog
					open={openUpgradeDialog}
					onClose={handleClose}
					onConfirm={() => handleConfirm(plan.name)}
					selectedPlan={plan}
					confirmIsLoading={confirmIsLoading}
				/>
			)}
		</>
	);
}
