export enum AnchorEnum {
	TOP = 'top',
	LEFT = 'left',
	BOTTOM = 'bottom',
	RIGHT = 'right'
}

export type Anchor = `${AnchorEnum}`;

export enum FilterTypeEnum {
	SELECT = 'select',
	CHIP = 'chip',
	TEXTINPUT = 'textinput',
	SECTION_HEADER = 'sectionHeadline'
}

// line of text between filter categories
export interface FilterSectionHeadline {
	filterType?: FilterTypeEnum.SECTION_HEADER;
	label: string;
}

export interface SelectFilterItem {
	label: string;
	value: string | false;
}
// this is rendered as dropdown select
export interface SelectFilter {
	filterType?: FilterTypeEnum.SELECT;
	filterKey: string;
	filterId?: string;
	placeholder: string;
	items: SelectFilterItem[]; // false is used to nullify filter
	resetItem?: { label: string }; // optional | if set, a reset item is added to the dropdown
	defaultValue?: string; // optional | set to value of item
}
// this is a multi-select filter
export interface ChipFilter {
	filterType?: FilterTypeEnum.CHIP;
	filterKey: string;
	filterId?: string;
	placeholder: string;
	items: {
		label: string;
		value: string;
		exclusive?: boolean; // optional | if true, the selected item can not be combined with other items
	}[];
	resetItem?: { label: string };
	defaultValue?: string[]; // optional | set to value of item
}

export interface TextSearchFilter {
	filterKey: string;
	placeholder?: string;
	defaultValue?: string;
}
export interface FilterQuery {
	ChipFilter<T extends ChipFilter>(filter: T): Record<T['filterKey'], string[]>;
	SelectFilter<T extends SelectFilter>(filter: T): Record<T['filterKey'], string>;
	textSearch: string;
}

export type FilterTypes = SelectFilter | ChipFilter | FilterSectionHeadline;
export interface FilterProps {
	isChangingFilter?: boolean;
	side: AnchorEnum; // slide in side
	textSearch: TextSearchFilter;
	filters: FilterTypes[];
	updateFilters?: FilterTypes[]; // use this to trigger a filter after render
	drawerHeadline?: string;
	onChange?: (filterQuery) => void;
}

export interface FiltersFromUrl {
	title: string;
	assignee: { id: string };
	customer: { id: string };
	status: string[];
}
