import { useMediaQuery } from '@mui/material';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ShieldIcon from '@mui/icons-material/ShieldOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { useTheme } from '@mui/material/styles';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Feature, useFeature } from '../acl/features';
import { State } from '../reducers';
import { closeSidebar } from './actions';
import { OfferRequestSubList } from './OfferRequestSubList';
import { getStatusFromSearch } from '../offer-requests/actions';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { logout } from '../auth/actions';
import { RelayLogo } from '../../components/RelayLogo';
import Drawer from '../../mui-custom/Drawer';

export function SidebarApp(props: {}) {
	const { open } = useSelector((state: State) => state.sidebar);
	const {
		filters: { status }
	} = useSelector((state: State) => state.offerRequests);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { search } = useLocation();
	const theme = useTheme();
	const hasSmallWidth = useMediaQuery(theme.breakpoints.down('md'));
	const isOpen = !hasSmallWidth || open;
	const hasAppointmentsEnabled = useFeature(Feature.AppointmentView);
	const hasDocumentsEnabled = useFeature(Feature.DocumentView);
	const hasOffersEnabled = useFeature(Feature.OfferView);
	const hasInsuranceEnabled = useFeature(Feature.InsuranceObject);
	const hasPropertiesViewEnabled = useFeature(Feature.PropertyView);

	const onClose = () => dispatch(closeSidebar());

	return (
		<Drawer
			open={isOpen}
			onClose={onClose}
			Top={[
				{
					id: 'home',
					to: '/app/home',
					Icon: <HomeIcon />,
					text: t('general:sidebar_home'),
					Logo: <RelayLogo show="mobile" style={{ marginLeft: 6 }} onClick={onClose} />
				},
				{
					id: 'orders',
					to: { pathname: '/app/orders', search },
					Icon: <img src="./../../static/images/Order.svg" alt="Order" />,
					text: t('general:sidebar_orders')
				},
				{
					show: hasOffersEnabled,
					id: 'offers',
					to: {
						pathname: '/app/offer-requests',
						search: `status=${getStatusFromSearch(status || '')}`
					},
					Icon: <img src="./../../static/images/Offer.svg" alt="Offer" />,
					text: t('general:sidebar_offers'),
					SubList: <OfferRequestSubList />
				},
				{
					id: 'customers',
					to: { pathname: '/app/customers', search },
					Icon: <PeopleOutlinedIcon />,
					text: t('general:customers')
				},
				{
					show: hasPropertiesViewEnabled,
					id: 'properties',
					to: { pathname: '/app/properties', search },
					Icon: <OtherHousesOutlinedIcon />,
					text: t('general:properties')
				},
				{
					show: hasInsuranceEnabled,
					id: 'insurance',
					to: { pathname: '/app/insurances', search },
					Icon: <ShieldIcon />,
					text: t('general:insurances')
				},
				{
					show: hasAppointmentsEnabled,
					id: 'appointments',
					to: '/app/appointments',
					Icon: <CalendarTodayOutlinedIcon />,
					text: t('general:sidebar_appointments')
				},
				{
					show: hasDocumentsEnabled,
					id: 'documents',
					to: '/app/documents',
					Icon: <FolderOutlinedIcon />,
					text: t('general:sidebar_documents')
				},
				{
					id: 'settings',
					to: '/app/settings/subscription',
					Icon: <ManageAccountsOutlinedIcon />,
					text: t('general:sidebar_profile')
				}
			]}
			Bottom={[
				{
					id: 'help',
					to: 'https://hilfe.relay.immo/de-form/',
					Icon: <HelpOutlinedIcon />,
					text: t('home:dashboard_help_center_tile_header')
				},
				{
					id: 'logout',
					Icon: <LogoutIcon />,
					text: t('general:cta_logout'),
					onClick: () => dispatch(logout())
				},
				{
					id: 'imprint',
					to: 'https://relay.immo/impressum/',
					text: t('general:footer_imprint')
				},
				{
					id: 'conditions',
					to: 'https://relay.immo/agb/',
					text: t('general:footer_terms_and_conditions')
				},
				{
					id: 'privacy',
					to: 'https://relay.immo/datenschutz/',
					text: t('general:footer_privacy')
				},
				{
					id: 'licenses',
					to: 'https://app.relay.immo/static/files/licenses.txt',
					text: t('general:footer_licenses')
				}
			]}
		/>
	);
}
