import React from 'react';
import { NavLink } from 'react-router-dom';
import { useMobile } from '../hooks/useBreakpoints';

export const RelayLogo = ({
	show = '',
	style = {},
    onClick = null
}: {
	show?: '' | 'mobile' | 'desktop';
	style?: object;
    onClick?: () => void;
}) => {
	const isMobile = useMobile();
	if ((show === 'mobile' && !isMobile) || (show === 'desktop' && isMobile)) return null;

	return (
		<NavLink
			style={{
				cursor: 'pointer',
				...style
			}}
			to={{
				pathname: '/app/home'
			}}
            onClick={onClick}
		>
			<img
				id="navigation_logo"
				src="./../../static/images/relay-logo.svg"
				alt="relay logo"
				style={{
					width: 100,
					height: 39,
					margin: 12
				}}
			/>
		</NavLink>
	);
};
