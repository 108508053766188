import { UIAddress, UIAddressDTO } from '../addresses/browser.types';
import { MetaList } from '../browser.types';
import { User } from '../db/models/User';
import { Document } from '../db/models/Document';
import { IsDefined, IsEmail, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export interface GetCredentialsReturnData {
	[key: string]: { username: string };
}
export interface ResponseCustomer {
	id: number;
	name: string;
	emailAddress: string;
	telephone: string;
	address?: UIAddress;
	internalId?: string;
	autoAssignUserId?: number;
	status: string;
	statusUpdatedAt: string;
	origin?: CustomerOrigin;
}
export interface ResponseCustomerSysadmin extends ResponseCustomer {
	techemId?: string;
	oasisId?: number;
	assfinetId?: string;
	accountId: number;
	externalId: string;
	casaviTenantId?: number;
	createdAt: string;
	documents?: Document[];
}

export class PatchCustomerBodySysadminDTO {
	@IsOptional()
	@IsString()
	oasisId?: number;
	@IsOptional()
	@IsString()
	techemId?: string;
	@IsOptional()
	@IsString()
	assfinetId?: string;
}

export interface PatchCustomerBodySysadmin {
	oasisId?: number;
	techemId?: string;
	assfinetId?: string;
}

export class PatchCustomerBodyDTO {
	@IsOptional()
	@IsString()
	internalId?: string;

	@IsOptional()
	@IsString()
	status?: string;

	@IsOptional()
	@IsNumber()
	autoAssignUserId?: number;
}
export class CreateCustomerBodyDTO {
	@IsOptional()
	@IsString()
	internalId?: string;

	@IsNotEmpty()
	@IsString()
	status: CustomerStatus | CustomerStatus[keyof CustomerStatus];

	@IsOptional()
	@IsString()
	name?: string;

	@IsOptional()
	@IsEmail()
	emailAddress?: string;

	@IsOptional()
	address?: UIAddressDTO;
	@IsOptional()
	@IsString()
	telephone?: string;
	@IsOptional()
	@IsString()
	origin?: CustomerOrigin;
}

export interface CreateCustomerBody {
	internalId?: string;
	status: CustomerStatus | CustomerStatus[keyof CustomerStatus];
	name?: string;
	emailAddress?: string;
	address?: UIAddress;
	telephone?: string;
	origin?: CustomerOrigin;
}

export class PostCustomerBodyDTO {
	@IsOptional()
	@IsString()
	token?: string;

	@IsDefined()
	body: CreateCustomerBodyDTO;
}
export interface UICustomer {
	id?: number;
	name: string;
	casaviContactId?: string;
	casaviTenantId?: string;
	accountId?: number;
	emailAddress: string;
}
export interface UICustomerStatistics {
	ALL?: number;
	OPEN?: number;
	DECLINED?: number;
	ACCEPTED?: number;
}

export interface CustomerListResponse
	extends MetaList<
		ResponseCustomer,
		{
			currentAcceptedCount: number;
			maxAcceptedCount: number;
			canAcceptMore: boolean;
		}
	> {
	possibleAutoAssignUsers: User[];
}

export enum CustomerStatus {
	Pending = 'PENDING',
	Accepted = 'ACCEPTED',
	Rejected = 'REJECTED',
	Requested = 'REQUESTED'
}

export enum CustomerOrigin {
	Relay = 'relay',
	Casavi = 'casavi',
	AccountCreation = 'accountCreation'
}
