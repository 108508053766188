import { Chip, Stack } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';

export function SearchBarChips({ filters, filterState, updateFilterState, disabledFilter }) {
	return (
		<Stack sx={{ m: 1 }}>
			{Array.isArray(filters) &&
				filters.map(({ filterKey, items }) => (
					<span key={filterKey}>
						{items.map(({ value, label }) => (
							<Chip
								key={value}
								onClick={() => updateFilterState(filterKey, value)}
								variant={filterState[filterKey]?.[value] ? 'filled' : 'outlined'}
								size="small"
								color={'primary'}
								label={`${label}`}
								icon={filterState[filterKey]?.[value] ? <CheckIcon /> : null}
								sx={{ m: 1 }}
								disabled={disabledFilter}
							/>
						))}
					</span>
				))}
		</Stack>
	);
}
