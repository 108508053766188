import React from 'react';
import { Route } from 'react-router-dom';

export const TicketsApp = () => {
	return (
		<div>
			tickets module overview
			<Route path="/app/tickets/add" component={() => <div>ticket creation page</div>} />
			<Route
				path="/app/tickets/add/custom-fields"
				component={() => <div>custom fields page</div>}
			/>
		</div>
	);
};
