import React from 'react';
import { Alert, AlertColor, AlertTitle, Avatar, Stack, Typography, useTheme } from '@mui/material';
import { dateTime } from '../../lib/dateHelper';
import { UIOrderComment, UIOrderStatus } from '@api/v1';
import { AttachmentThumbnail } from './AttachmentThumbnail';
import { getStatusChangeLabel } from '../../core/orders/single/Comment';
import { useTranslation } from 'react-i18next';

export const Message = ({ item: comment }: { item: UIOrderComment }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { text, source, author, isInternal } = comment;
	const { avatar, initials, type } = author;
	const isRightAligned = type === 'INTERNAL';
	const backGroundColor = isRightAligned
		? theme.palette.secondaryShades8p
		: theme.palette.primaryShades8p;
	return (
		<Stack gap={1} direction={isRightAligned ? 'row-reverse' : 'row'}>
			<Avatar variant="circular" src={avatar}>
				{avatar ? '' : initials}
			</Avatar>
			{source !== 'CHANGE' ? (
				<Stack alignItems={isRightAligned ? 'flex-end' : 'flex-start'} sx={{ width: 1 }}>
					<Typography sx={{ borderRadius: 1, background: backGroundColor, p: 1 }}>
						{comment.text}
					</Typography>
					{!!comment.attachments?.length && (
						<Stack sx={{ gap: 0.5, width: 1 }}>
							{comment.attachments.map((a) => (
								<AttachmentThumbnail
									key={a.id}
									link={a.url}
									thumbnail={a.thumbnailS3Key}
									backGroundColor={backGroundColor}
								/>
							))}
						</Stack>
					)}
					<Typography variant="caption">
						<Typography
							noWrap
							variant="caption"
							sx={{ mr: 1, color: isInternal ? theme.palette.grey[400] : 'orange' }}
						>
							{isInternal ? t('orders:comment_internal') : t('orders:comment_external')}
						</Typography>
						{dateTime(comment.createdAt).toFormat('HH:mm')}
					</Typography>
				</Stack>
			) : (
				<Stack alignItems={isRightAligned ? 'flex-end' : 'flex-start'}>
					<Alert severity={getStatusVariant(comment.statusChange)}>
						<AlertTitle
							dangerouslySetInnerHTML={{
								__html: comment.statusChange
									? `${t('orders:comment_status_change_v2', {
											status: getStatusChangeLabel(comment.statusChange, t)
									  })}<br>${text}`
									: text
							}}
						/>
					</Alert>
					<Typography variant="caption">
						{dateTime(comment.createdAt).toFormat('dd.MM.yyyy, HH:mm')}
					</Typography>
				</Stack>
			)}
		</Stack>
	);
};

export const getStatusVariant = (status: UIOrderStatus) =>
	(({
		DECLINED: 'error',
		FINISHED: 'success',
		IN_PROGRESS: 'info',
		OPEN: 'warning'
	}[status] ?? 'info') as AlertColor);
