import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { MaterialDropzone, DropzoneProps } from '../../../components/Dropzone';
import { DialogProps } from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

interface DropzoneDialogProps {
	open: boolean;
	onClose: DialogProps['onClose'];
	title: string;
	dropzoneProps: DropzoneProps;
}

export const DropzoneDialog = ({ open, onClose, title, dropzoneProps }: DropzoneDialogProps) => {
	const { t } = useTranslation();
	return (
		<Dialog open={open}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<MaterialDropzone id="file_upload_dialog_dropzone" {...dropzoneProps} />
			</DialogContent>
			<DialogActions sx={{ width: 'min(32rem, calc(100vw - 4rem))' }}>
				<Button id="file_upload_dialog_button_close" onClick={onClose}>
					{t('general:cta_close')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
