import { Card, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { State } from '../../reducers';
import { usePermission } from '../../acl/permissions';
import { useHistory } from 'react-router-dom';
import {
	useFeature,
	Feature,
	AccountSpecificFeature,
	useAccountSpecificFeature
} from '../../acl/features';
import Page from '../../../mui-custom/Page/Page';
import MuiAlert from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';
import ActionBar from '../../../mui-custom/ActionBar/ActionBar';
import { TextGroupHeadline } from '../../../mui-custom/TextGroupSmall/TextGroupHeadline';
import { TextGroupText } from '../../../mui-custom/TextGroupSmall/TextGroupText';
import SecondaryButton, { ButtonColor } from '../../../mui-custom/Button/SecondaryButton';
import { getUserName } from './utils';
import {
	openDeactivationDialog,
	openInvitationDialog,
	openDeletionDialog,
	loadUsers
} from './actions';
import {
	AdminExclusivePermission,
	OwnerExclusivePermission
} from '../../../../server/permissions/permission';
import { handleSubmit } from '../../../components/EmployeeCreationDialog/actions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { UserCreationPage } from './UserCreationPage';

export function AdminUsersApp() {
	const history = useHistory();
	const [isOnCreationPage, setIsOnCreationPage] = useState(false);
	const { users, currentUserId, maxActiveUsers } = useSelector((state: State) => ({
		currentUserId: state.auth.user.id,
		users: state.admin.users.list,
		maxActiveUsers: state.admin?.users?.list?.metaData?.maxActiveUsers || '?'
	}));
	const dispatch = useDispatch();
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);

	const { t } = useTranslation();
	const hasAccountView = usePermission(OwnerExclusivePermission.VIEW_ACCOUNT);
	const hasChargebeeCheckout = useFeature(Feature.ChargebeeCheckout);
	const hasDeletionRights = usePermission(AdminExclusivePermission.DELETE_USER);
	useEffect(() => {
		dispatch(loadUsers());
	}, []);

	const onSubmit = () => {
		dispatch(handleSubmit());
		setIsOnCreationPage(false);
		dispatch(loadUsers());
	};

	const onCancel = () => {
		setIsOnCreationPage(false);
		dispatch(loadUsers());
	};

	if (isOnCreationPage) {
		return <UserCreationPage onSubmit={onSubmit} onCancel={onCancel} />;
	}

	return (
		<Page
			headline={
				<ActionBar
					primary={{
						children: t('admin:user_create_user_button'),
						color: ButtonColor.Creative,
						onClick: () => setIsOnCreationPage(true)
					}}
				>
					{t('admin:user_page_headline')}
				</ActionBar>
			}
		>
			{/* AlertBox */}
			{!hasPricingModelV2 && (
				<MuiAlert icon={false} severity="info">
					<MuiAlertTitle>{t('admin:user_page_alert_header', { maxActiveUsers })}</MuiAlertTitle>
					{t('admin:user_page_alert_text')}
					{hasChargebeeCheckout && (
						<SecondaryButton
							disabled={!hasAccountView}
							color={ButtonColor.Creative}
							size="small"
							onClick={() =>
								history.push({
									pathname: '/app/admin/account?action=upgrade'
								})
							}
						>
							{hasAccountView
								? t('admin:licenses_upgrade_button')
								: t('admin:contact_account_owner')}
						</SecondaryButton>
					)}
				</MuiAlert>
			)}
			<Stack spacing={1}>
				{users?.data.length ? (
					users.data.map((user) => (
						<UserCard
							currentUserId={currentUserId}
							key={user.id}
							userId={user.id}
							name={getUserName(user)}
							email={user.email}
							activated={user.active}
							handlePrimaryClick={
								user.active
									? () => dispatch(openDeactivationDialog(user))
									: () => dispatch(openInvitationDialog(user))
							}
							handleSecondaryClick={
								hasDeletionRights ? () => dispatch(openDeletionDialog(user)) : undefined
							}
						/>
					))
				) : (
					<LoadingScreen />
				)}
			</Stack>
		</Page>
	);
}

const UserCard = ({
	userId,
	name,
	email,
	activated,
	handlePrimaryClick,
	handleSecondaryClick,
	currentUserId
}: {
	userId: number;
	name: string;
	email: string;
	activated: boolean;
	handlePrimaryClick: React.MouseEventHandler<HTMLButtonElement>;
	handleSecondaryClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
	currentUserId: number;
}) => {
	const { t } = useTranslation();
	const disableActions = userId === currentUserId;
	return (
		<Card sx={{ px: 2, py: 1 }}>
			<Stack direction="row" spacing={1}>
				<TextGroupHeadline>{name}</TextGroupHeadline>
				{activated && <CheckCircleIcon color="success" />}
			</Stack>
			<TextGroupText>{email}</TextGroupText>
			<ActionBar
				primary={{
					children: t(
						activated
							? 'admin:user_deactivation_switch_tooltip_text'
							: 'admin:user_invitation_switch_tooltip_text'
					),
					color: activated ? ButtonColor.Neutral : ButtonColor.Creative,
					disabled: disableActions,
					onClick: handlePrimaryClick
				}}
				secondary={{
					children: t('admin:user_deletion_dialog_cta_button'),
					color: ButtonColor.Destructive,
					disabled: disableActions || activated,
					onClick: handleSecondaryClick
				}}
				primaryAsSecondary={true}
			/>
		</Card>
	);
};
