import React from 'react';
import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { useMobile } from '../../hooks/useBreakpoints';

interface DefinitionListItem {
	key: string;
	value: string;
}

export interface DefinitionListProps {
	dense?: boolean;
	items: DefinitionListItem[];
	sx?: object;
}

function DefinitionListItem({
	item,
	dense
}: {
	key?: unknown;
	item: DefinitionListItem;
	dense: boolean;
}) {
	const theme = useTheme();
	const isMobile = useMobile();
	return (
		<Stack
			spacing={3}
			direction="row"
			sx={{ p: dense ? theme.spacing(1, 0) : theme.spacing(2, 0) }}
		>
			<Typography
				variant="body1"
				sx={{
					flexBasis: isMobile ? theme.spacing(11) : theme.spacing(20),
					color: theme.palette.text.secondary,
					flexShrink: 0,
					hyphens: 'auto'
				}}
				lang="de"
			>
				{item.key}
			</Typography>
			<Typography
				variant="body1"
				sx={{
					color: theme.palette.text.primary
				}}
			>
				{item.value}
			</Typography>
		</Stack>
	);
}

export function DefinitionList({ items, dense = false, sx = {} }: DefinitionListProps) {
	return (
		<Stack divider={<Divider flexItem />} sx={sx}>
			{items.map((item) => (
				<DefinitionListItem key={item.key} item={item} dense={dense} />
			))}
		</Stack>
	);
}

export type DefinitionList = ReturnType<typeof DefinitionList>;
