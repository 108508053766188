import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Email } from './Email';
import { ProfileForm } from './ProfileForm';
import Page from '../../mui-custom/Page/Page';
import { MarkEmailUnreadOutlined, CreditCardOutlined } from '@mui/icons-material';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { AdminAccountPage } from '../admin/account/App';
import { AdminUsersApp } from '../admin/users/App';
import { useLocation, useParams } from 'react-router-dom';
import { AdminUserDeactivationDialogV2 } from '../admin/users/DeactivationDialog';
import { AdminUserInvitationDialogV2 } from '../admin/users/InvitationDialog';
import { AdminUserDeletionDialogV2 } from '../admin/users/DeletionDialog';
import { useMobile } from '../../hooks/useBreakpoints';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		overflowY: 'scroll',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#fff'
	},
	header: {
		backgroundColor: theme.palette.grey[400],
		zIndex: 1
	},
	link: {
		textDecoration: 'none',
		color: 'inherit'
	},
	indicator: {
		backgroundColor: '#fff'
	},
	container: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		marginTop: '10vh'
	}
}));

export function ProfileApp(props) {
	const { section } = useParams();
	const location = useLocation();
	const [selectedTab, setSelectedTab] = useState(getActiveTab(section));
	const { t } = useTranslation();
	const classes = useStyles(props);
	useEffect(() => {
		const temp = getActiveTab(section);
		setSelectedTab(temp);
	}, []);

	const isMobile = useMobile();

	return (
		<Grid className={classes.root} sx={{ m: isMobile ? 0 : 2, mb: 0 }}>
			<Container disableGutters={true} maxWidth="lg">
				<Page
					activePageId={selectedTab}
					navItems={[
						{
							icon: CreditCardOutlined,
							label: t('general:payment_details'),
							link: '/app/settings/subscription'
						},
						{
							icon: AccountCircleOutlinedIcon,
							label: t('general:profile'),
							link: '/app/settings/profile'
						},
						{
							icon: GroupOutlinedIcon,
							label: t('general:account_users'),
							link: '/app/settings/user-license'
						},
						{
							icon: MarkEmailUnreadOutlined,
							label: t('general:email_notifications'),
							link: '/app/settings/e-mail-notifications'
						}
					]}
					innerPages={[
						<AdminAccountPage key={0} />,
						<ProfileForm key={1} />,
						<AdminUsersApp key={2} />,
						<Email key={3} />
					]}
					location={location}
				/>
				<AdminUserDeactivationDialogV2 />
				<AdminUserInvitationDialogV2 />
				<AdminUserDeletionDialogV2 />
			</Container>
		</Grid>
	);
}

function getActiveTab(section: string) {
	if (section === 'subscription') {
		return 0;
	} else if (section === 'profile') {
		return 1;
	} else if (section === 'user-license') {
		return 2;
	} else if (section === 'e-mail-notifications') {
		return 3;
	}
	return 0;
}
