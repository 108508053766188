import { useAccountSpecificFeature } from '../core/acl/features';
import {
	AccountAlert,
	FreeAccountCard,
	PaymentMethodMissingAlert
} from '../core/admin/account/App';
import { BasicChip } from '../core/admin/account/ChipFilled';
import ContentCard from '../mui-custom/ContentCard/ContentCard';
import { PriceTag, PriceTagCell } from '../mui-custom/PriceTagCell';
import React, { useEffect, useRef, useState } from 'react';

import {
	ChargebeePlansV2,
	ChargebeePlans,
	ChargebeeSubscriptionStatus,
	RELAY_STARTER,
	RELAY_SMALL,
	RELAY_MEDIUM,
	RELAY_PRO,
	RELAY_BASIC,
	RELAY_FREE,
	RELAY_PREMIUM,
	ALL_PRICES,
	ORDER_CREDITS,
	RELAY_PREMIUM_DB
} from '../../server/chargebee/chargebee.enums';
import { AccountSpecificFeature } from '../../server/features/feature';
import { UpgradeButton } from './UpgradeButton/App';

import MuiAlert from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ArrowDropDown } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Grid,
	List,
	ListItem,
	useTheme
} from '@mui/material';
import { getPrettyPlanName } from '../../app/core/admin/account/utils';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../core/reducers';
import SecondaryButton from '../mui-custom/Button/SecondaryButton';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import PrimaryButton from '../mui-custom/Button/PrimaryButton';
import { redeemCoupon } from '../core/auth/actions';
import { Coupon } from 'server/accounts/browser.types';
import { formatPrice } from '../mui-custom/helpers';

export const getPlanObject = (plan, t) => {
	switch (plan) {
		case 'relay-free':
			return {
				current: 'relay-free',
				next: 'relay-basic',
				currentText: t('admin:account_pricing_free_plan'),
				nextText: t('admin:account_pricing_basic_plan')
			};
		case 'relay-basic':
			return {
				current: 'relay-basic',
				next: 'relay-premium',
				currentText: t('admin:account_pricing_basic_plan'),
				nextText: t('admin:account_pricing_premium_plan')
			};
		case 'relay-premium':
		case 'relay-premium-db':
			return {
				current: 'relay-premium',
				next: 'relay-enterprise',
				currentText: t('admin:account_pricing_premium_plan'),
				nextText: t('admin:account_pricing_enterprise_plan')
			};
		default:
			return null;
	}
};

const getPricingV2Benefit = (benefit: string) => {
	return {
		[RELAY_SMALL]: benefit,
		[RELAY_MEDIUM]: benefit,
		[RELAY_PRO]: benefit
	};
};

export const createPricingV2Benefits = (t: i18next.TFunction) => [
	{
		[RELAY_SMALL]: t('admin:account_pricing_order_management_V2', {
			credits: ORDER_CREDITS[RELAY_SMALL]
		}),
		[RELAY_MEDIUM]: t('admin:account_pricing_order_management_V2', {
			credits: ORDER_CREDITS[RELAY_MEDIUM]
		}),
		[RELAY_PRO]: t('admin:account_pricing_order_management_V2', {
			credits: ORDER_CREDITS[RELAY_PRO]
		})
	},
	getPricingV2Benefit(t('admin:account_pricing_order_management_and_history')),
	getPricingV2Benefit(t('admin:account_pricing_offer_management')),
	getPricingV2Benefit(t('admin:account_pricing_amount_users')),
	getPricingV2Benefit(t('admin:account_pricing_employee_management')),
	getPricingV2Benefit(t('admin:account_pricing_amount_customers')),
	getPricingV2Benefit(t('admin:account_pricing_termination_notice')),
	getPricingV2Benefit(t('admin:account_pricing_support_feature'))
];
export const createBenefits = (t: i18next.TFunction) => [
	{
		[RELAY_FREE]: t('admin:account_pricing_order_management'),
		[RELAY_BASIC]: t('admin:account_pricing_order_management'),
		[RELAY_PREMIUM]: t('admin:account_pricing_order_management')
	},
	{
		[RELAY_FREE]: '-',
		[RELAY_BASIC]: t('admin:account_pricing_employee_management'),
		[RELAY_PREMIUM]: t('admin:account_pricing_employee_management')
	},
	{
		[RELAY_FREE]: `1 ${t('admin:account_user')}`,
		[RELAY_BASIC]: `4 ${t('admin:account_users')}`,
		[RELAY_PREMIUM]: `15 ${t('admin:account_users')}`
	},
	{
		[RELAY_FREE]: `1 ${t('admin:account_property')}`,
		[RELAY_BASIC]: `4 ${t('admin:account_properties')}`,
		[RELAY_PREMIUM]: `10 ${t('admin:account_properties')}`
	},
	{
		[RELAY_FREE]: t('admin:account_pricing_monthly_cancellable'),
		[RELAY_BASIC]: t('admin:account_pricing_monthly_cancellable'),
		[RELAY_PREMIUM]: t('admin:account_pricing_monthly_cancellable')
	},
	{
		[RELAY_FREE]: t('admin:account_support'),
		[RELAY_BASIC]: t('admin:account_support'),
		[RELAY_PREMIUM]: t('admin:account_support')
	}
];

const FreeAccountAlert = () => {
	const { t } = useTranslation();
	return (
		<MuiAlert
			severity="warning"
			action={
				<SecondaryButton
					onClick={() => (window.location.href = `mailto:${t('general:support_email')}`)}
				>{`${t('admin:relay_free_plan_info_alert_action')}`}</SecondaryButton>
			}
		>
			<MuiAlertTitle>{t('admin:relay_free_plan_info_alert_title')}</MuiAlertTitle>
			{t('admin:relay_free_plan_info_alert_description')}
		</MuiAlert>
	);
};

// returns an array of prices, accounted for coupon discounts
export function usePlanPriceCalculator() {
	const prices = { ...ALL_PRICES };
	const coupon: Coupon = useSelector((state: State) => state.auth.account?.coupon);
	const calc = (estimate, planName) => {
		if (estimate?.discounted_price) return estimate.discounted_price / 100;
		return ALL_PRICES[planName] / 100;
	};

	Object.keys(prices).forEach((planName) => {
		const estimate = coupon?.estimates?.find((estimate) => estimate.entity_id === planName);
		prices[planName] = calc(estimate, planName);
	});

	return prices;
}

export const useVisiblePlans = (
	isPricingModelV2Only = false
): (ChargebeePlans | ChargebeePlansV2)[] => {
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);
	const systemPlans = [RELAY_PREMIUM_DB, RELAY_STARTER];
	return Object.values(
		hasPricingModelV2 || isPricingModelV2Only ? ChargebeePlansV2 : ChargebeePlans
	).filter((plan) => !systemPlans.includes(plan));
};

export const PricingDetails = () => {
	const { t } = useTranslation();
	const { palette, spacing } = useTheme();
	const dispatch = useDispatch();
	const planPrices = usePlanPriceCalculator();

	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);
	const hasCouponPromo = useAccountSpecificFeature(AccountSpecificFeature.CouponPromo);

	const { paymentSources } = useSelector((state: State) => state.admin.account);
	const { user } = useSelector((state: State) => state.auth);
	const coupon: Coupon = useSelector((state: State) => state.auth.account?.coupon);

	const benefitsV2 = createPricingV2Benefits(t);
	const benefits = createBenefits(t);
	const planNames = useVisiblePlans();
	const { account, activeSubscription } = useSelector((state: State) => state.admin.account);

	const plan: string | ChargebeePlansV2 = hasPricingModelV2
		? activeSubscription?.plan_id
		: getPlanObject(account?.plan || null, t)?.current; // remove this

	/* newPricingUser = a user that already existed and is newly switched to new pricing*/
	const [newPricingUser, setNewPricingUser] = useState(false);
	const [couponCode, setCouponCode] = useState('');
	const [couponButtonLoading, setCouponButtonLoading] = useState(false);
	const formRef = useRef(null);
	const onSubmit = () => {
		setCouponButtonLoading(true);
		dispatch(redeemCoupon(couponCode));
	};
	useEffect(() => {
		if (coupon) {
			setCouponButtonLoading(false);
		}
	}, [coupon]);
	useEffect(() => {
		if (
			!Object.values(ChargebeePlansV2)?.includes(plan) &&
			activeSubscription?.status !== ChargebeeSubscriptionStatus.IN_TRIAL &&
			activeSubscription?.status !== ChargebeeSubscriptionStatus.CANCELLED &&
			user.activePlanUntil === null &&
			hasPricingModelV2
		) {
			setNewPricingUser(true);
		}
	}, [activeSubscription?.status]);
	return (
		<>
			{hasCouponPromo && !activeSubscription?.coupon && (
				<MuiAlert
					icon={false}
					severity="success"
					sx={{
						width: '100%',
						display: 'block'
					}}
				>
					<MuiAlertTitle>
						<Typography variant="h6">
							{t('admin:account_pricing_coupon_promo_alert_title')}
						</Typography>
					</MuiAlertTitle>

					<Typography variant="body1" sx={{ margin: '8px 0px 8px 0px' }}>
						{t('admin:account_pricing_coupon_promo_alert_description')}
					</Typography>
					<ValidatorForm ref={formRef} onSubmit={onSubmit}>
						<Grid container spacing={2}>
							<Grid item>
								<TextValidator
									size="medium"
									variant="outlined"
									name="couponInput"
									label={t('admin:account_pricing_coupon_promo_alert_label')}
									value={couponCode}
									onChange={(e) => setCouponCode(e.target.value)}
									sx={{
										'background-color': '#FFFFFF'
									}}
									fullWidth
								/>
							</Grid>
							<Grid item>
								<PrimaryButton
									size="large"
									type="submit"
									variant="contained"
									isLoadingButton
									loading={couponButtonLoading}
									sx={{ marginTop: spacing(0.5) }}
								>{`${t('general:submit')}`}</PrimaryButton>
							</Grid>
						</Grid>
					</ValidatorForm>
				</MuiAlert>
			)}
			<div dangerouslySetInnerHTML={{ __html: t('admin:account_pricing_footnote_enterprise') }} />
			{hasPricingModelV2 && paymentSources?.length === 0 && <PaymentMethodMissingAlert />}
			{account?.deactivatedAt === null && <AccountAlert />}
			{account?.deactivatedAt && <FreeAccountAlert />}
			<TableContainer>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							{planNames.map((planName) => (
								<TableCell key={planName}>
									<div style={{ display: 'flex' }}>
										<Typography variant="h6">{getPrettyPlanName(planName)}</Typography>
										{plan === planName && account?.deactivatedAt === null && (
											<BasicChip
												size="small"
												label={`${t('admin:account_pricing_current_plan_label')}`}
											/>
										)}
										{plan === planName &&
											account?.deactivatedAt === null &&
											!!activeSubscription?.coupon && (
												<BasicChip color="success" size="small" label="-30%" />
											)}
									</div>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableRow>
						{planNames.map((planName) => (
							<PriceTagCell
								key={planName}
								price={planPrices[planName]}
								strikeThrough={coupon?.coupon_code ? String(ALL_PRICES[planName] / 100) : ''}
							/>
						))}
					</TableRow>
					<TableBody>
						{(hasPricingModelV2 ? benefitsV2 : benefits).map((row, id) => (
							<TableRow key={id}>
								{planNames.map((planName) => (
									<TableCell key={planName}>
										<Typography
											dangerouslySetInnerHTML={{
												__html: row[planName]
											}}
										/>
									</TableCell>
								))}
							</TableRow>
						))}
						{newPricingUser && (
							<TableRow>
								<TableCell>{t('admin:account_pricing_new_trial_benefit')}</TableCell>
								<TableCell>{t('admin:account_pricing_new_trial_benefit')}</TableCell>
								<TableCell>{t('admin:account_pricing_new_trial_benefit')}</TableCell>
							</TableRow>
						)}
						<TableRow>
							{planNames.map((planName) => (
								<TableCell key={planName}>
									{(plan !== planName || account?.deactivatedAt) && (
										<UpgradeButton
											buttonText={
												!account?.deactivatedAt
													? t('admin:licenses_upgrade_to_button_V2', {
															planName: getPrettyPlanName(planName)
													  })
													: t('admin:licenses_upgrade_button_new_plan')
											}
											plan={{
												name: planName,
												price: formatPrice(planPrices[planName]),
												credits: ORDER_CREDITS[planName]
											}}
										/>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			{hasPricingModelV2 && (
				<Typography variant="body2" sx={{ mt: 2, color: palette.text.secondary }}>
					{t('admin:account_pricing_cost_additional_orders')}
				</Typography>
			)}
		</>
	);
};

export const PricingDetailsPaywall = ({ onClose }: { onClose: () => void }) => {
	const { t } = useTranslation();

	const pricingDetailsV2Prices = {
		[RELAY_SMALL]: ALL_PRICES[RELAY_SMALL],
		[RELAY_MEDIUM]: ALL_PRICES[RELAY_MEDIUM],
		[RELAY_PRO]: ALL_PRICES[RELAY_PRO]
	};
	const pricingDetailsV2Credits = {
		[RELAY_SMALL]: t('admin:account_pricing_order_management_V2', {
			credits: ORDER_CREDITS[RELAY_SMALL]
		}),
		[RELAY_MEDIUM]: t('admin:account_pricing_order_management_V2', {
			credits: ORDER_CREDITS[RELAY_MEDIUM]
		}),
		[RELAY_PRO]: t('admin:account_pricing_order_management_V2', {
			credits: ORDER_CREDITS[RELAY_PRO]
		})
	};
	const benefitsV2 = createPricingV2Benefits(t);
	const { user } = useSelector((state: State) => state.auth);
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);
	const isPilotGroup1Account =
		hasPricingModelV2 && user.subscriptionStatus === null && user.activePlanUntil === null;

	const planNames = useVisiblePlans();
	return (
		<Grid container sx={{ mb: 2 }}>
			<Grid item xs={0} md={1}></Grid>
			<Grid item xs={12} md={10} mb={4}>
				<Grid container spacing={2}>
					{planNames.map((planName) => (
						<Grid item xs={12} md={4} key={planName}>
							<ContentCard
								actionBarPosition="top"
								actionBar={
									isPilotGroup1Account ? (
										<UpgradeButton
											buttonText={t('admin:plan_select_testphase_begin', {
												planName
											})}
											plan={{
												name: planName,
												price: pricingDetailsV2Prices[planName] / 100,
												credits: ORDER_CREDITS[planName]
											}}
											onClose={onClose}
										/>
									) : (
										<UpgradeButton
											buttonText={t('admin:plan_select', {
												planName
											})}
											plan={{
												name: planName,
												price: pricingDetailsV2Prices[planName] / 100,
												credits: ORDER_CREDITS[planName]
											}}
											onClose={onClose}
										/>
									)
								}
								headline={<Typography variant="h6">{getPrettyPlanName(planName)}</Typography>}
								text={
									<PriceTag price={pricingDetailsV2Prices[planName] / 100}>
										{t('admin:per_month')}
									</PriceTag>
								}
							>
								<Stack spacing={2} padding={'8px 0'}>
									<Typography
										sx={{ whiteSpace: 'pre-line' }}
										dangerouslySetInnerHTML={{
											__html: pricingDetailsV2Credits[planName]
										}}
									/>
								</Stack>
								<Divider />
								<Accordion defaultExpanded={false} disableGutters={true}>
									<AccordionSummary
										sx={{ padding: 0 }}
										expandIcon={<ArrowDropDown />}
										aria-controls="panel1-content"
										id="panel1-header"
									>
										{t('admin:account_pricing_plan_inclusives')}
									</AccordionSummary>
									<AccordionDetails sx={{ padding: 0 }}>
										<List sx={{ listStyleType: 'disc', pl: '12px' }}>
											{benefitsV2.slice(1).map((row) => (
												<ListItem key={row[planName]} sx={{ display: 'list-item', padding: 0 }}>
													<Typography>{row[planName]}</Typography>
												</ListItem>
											))}
										</List>
									</AccordionDetails>
								</Accordion>
							</ContentCard>
						</Grid>
					))}
				</Grid>
			</Grid>
			<FreeAccountCard />
		</Grid>
	);
};
