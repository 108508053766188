import { AppBar, IconButton, Slide, Stack, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resendConfirmEmail } from './actions';
import { GridCloseIcon } from '@mui/x-data-grid';
import Info from '@mui/icons-material/Info';
import { PrimaryButton } from '../mui-custom/Button';

const PADDING = 6;
export const BANNER_HEIGHT = `${40 + PADDING * 2}px`;

const useStyles = makeStyles((theme) => ({
	link: {
		textDecoration: 'underline',
		cursor: 'pointer',
		alignSelf: 'center'
	},
	banner: {
		'background-color': theme.palette.primary.dark,
		padding: `${PADDING}px`,
		'@media print': {
			display: 'none'
		}
	},
	warning: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.error.contrastText
	},
	info: {
		backgroundColor: theme.palette.info.main,
		color: theme.palette.info.contrastText,
		transitionProperty: 'margin',
		transitionDuration: '0.5s',
		alignItems: 'center'
	},
	closeButton: {
		color: theme.palette.common.white,
		float: 'right',
		padding: `${PADDING}px`
	},
	infoIcon: {
		alignSelf: 'center'
	}
}));

export function Banner({ email }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	return (
		<AppBar position="sticky" className={classes.banner}>
			<Typography align="center" color="inherit" variant="body2">
				{t('registration:banner_verify_email_address', {
					email
				})}
				&nbsp;<br></br>
				<a className={classes.link} onClick={() => dispatch(resendConfirmEmail())}>
					{t('registration:banner_resend_confirm_email_link')}
				</a>
			</Typography>
		</AppBar>
	);
}

export function SysAdminBanner() {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<AppBar position="sticky" className={`${classes.banner} ${classes.warning}`}>
			<Typography align="center" color="inherit" variant="h4">
				{t('sysadmin:logged_in_as_sysadmin_warning')}
			</Typography>
		</AppBar>
	);
}

export function PricingV2InfoBanner() {
	const classes = useStyles();
	const { t } = useTranslation();
	const [hideBanner, setHideBanner] = useState(false);
	const { palette } = useTheme();
	return (
		<Slide
			direction="down"
			in={!hideBanner}
			mountOnEnter
			unmountOnExit
			timeout={{ enter: 200, exit: 1000 }}
		>
			<AppBar
				position="sticky"
				style={{ backgroundColor: palette.primary.main, flexDirection: 'row' }}
				className={`${classes.banner} ${classes.info}`}
			>
				<Stack direction="row" spacing={1} justifyContent="center" sx={{ flex: 1 }}>
					<Info className={classes.infoIcon} />
					<Typography alignSelf="center" variant="body2">
						{t('general:pricing_v2_info_banner_message')}
					</Typography>
					<Link to="/app/settings/subscription" className={classes.link}>
						{/* Designer should change color to light token, temp solution '#8FB9FF' */}
						<PrimaryButton
							style={{ backgroundColor: 'white', color: palette.info.main }}
							size="small"
						>
							{t('general:pricing_v2_info_banner_button')}
						</PrimaryButton>
					</Link>
				</Stack>
				<IconButton
					aria-label="close"
					size="small"
					className={classes.closeButton}
					onClick={() => setHideBanner(true)}
				>
					<GridCloseIcon />
				</IconButton>
			</AppBar>
		</Slide>
	);
}
