import { Theme, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';
import Dropzone, { DropzoneProps, DropzoneState } from 'react-dropzone';
import uuid from 'uuid';
import { api } from '../../lib/api';
import { ThumbnailList } from './ThumbnailList';
import { DropzoneFile, OnChangeFileCallback, OnRemoveCallback } from './types';

const useStyles = makeStyles((theme: Theme) => ({
	zone: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		background: theme.palette.background.default,
		padding: theme.spacing(2),
		border: `1px dashed ${theme.palette.text.secondary}`,
		cursor: 'pointer',
		outline: 'none',
		transition: theme.transitions.create('background')
	},
	list: {
		paddingBottom: theme.spacing(2)
	},
	disabled: {
		cursor: 'not-allowed',
		color: theme.palette.text.disabled
	},
	accept: {
		background: theme.palette.success.light
	},
	reject: {
		background: theme.palette.error.light
	}
}));

interface OwnProps extends Omit<DropzoneProps, 'onDrop'> {
	id?: string;
	text: string;
	files?: DropzoneFile[];
	onDrop: (info: DropzoneFile) => void;
	onRemove: OnRemoveCallback;
	onChangeFile: OnChangeFileCallback;
}

interface MaterialDropzoneProps extends OwnProps {}

export const MaterialDropzone = ({
	id,
	text,
	files,
	onDrop,
	onRemove,
	onChangeFile,
	...props
}: MaterialDropzoneProps) => {
	const onDropFiles = (droppedFiles: File[]) => {
		droppedFiles.forEach((file) => {
			const tempId = uuid();
			const preview = URL.createObjectURL(file);
			onDrop({
				tempId,
				fileName: file.name,
				fileType: file.type,
				s3Key: null,
				preview,
				progress: 0
			});
			api
				.uploadFile(file, {
					onUploadProgress: (event) => {
						const percentCompleted = Math.floor((event.loaded * 100) / event.total);
						onChangeFile(tempId, {
							fileName: file.name,
							fileType: file.type,
							s3Key: null,
							preview,
							progress: Math.min(percentCompleted, 93)
						});
					}
				})
				.then((data) => {
					onChangeFile(tempId, {
						fileName: data.originalName,
						fileType: file.type,
						preview,
						s3Key: data.s3Key,
						thumbnailS3Key: data.thumbnailS3Key,
						progress: 100
					});
				})
				.catch(() => {
					onChangeFile(tempId, {
						fileName: file.name,
						fileType: file.type,
						s3Key: null,
						preview,
						progress: 100,
						error: true
					});
				});
		});
	};

	const classes = useStyles();
	return (
		<Dropzone onDropAccepted={(acceptedFiles) => onDropFiles(acceptedFiles)} {...props}>
			{({ getRootProps, getInputProps, isDragAccept, isDragReject }: DropzoneState) => (
				<div
					className={classNames(classes.zone, {
						[classes.accept]: isDragAccept,
						[classes.reject]: isDragReject,
						[classes.disabled]: props.disabled
					})}
					{...getRootProps()}
				>
					<input id={id} {...getInputProps()} />
					{files && files.length ? (
						<ThumbnailList files={files} onRemove={onRemove} className={classes.list} />
					) : null}
					<Typography color="inherit" variant="subtitle1">
						{text}
					</Typography>
					<CloudUploadIcon />
				</div>
			)}
		</Dropzone>
	);
};
