import * as React from 'react';
import { State } from 'app/core/reducers';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { dateTime } from '../../../lib/dateHelper';
import { Typography, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { teal, deepPurple } from '@mui/material/colors';

const mapStatusToColor = {
	OPEN: { bg: teal[50], text: teal[900] },
	SUBMITTED: { bg: deepPurple[50], text: deepPurple[900] },
	DECLINED: 'secondary'
};

export const OfferRequestList = () => {
	const { list, isLoading } = useSelector((state: State) => state.offerRequests);
	const theme = useTheme();
	const { t } = useTranslation();
	const { search } = useLocation();
	const history = useHistory();
	const mapStatusToLabel = {
		OPEN: t('offerRequest:status_OPEN').toUpperCase(),
		SUBMITTED: t('offerRequest:status_SUBMITTED').toUpperCase(),
		DECLINED: t('offerRequest:status_DECLINED').toUpperCase()
	};
	const handleCellClick = (offerRequestId: number) => {
		history.push({
			pathname: `/app/offer-requests/${offerRequestId}`,
			search
		});
	};
	if (isLoading) return <LoadingScreen />;
	if (!list?.data?.length) {
		return (
			<Paper variant="outlined" sx={{ m: 1, py: 3, px: 1, width: 'auto' }}>
				<Typography>{t('orders:list_none_here')}</Typography>
			</Paper>
		);
	}
	return (
		<TableContainer sx={{ mx: 1, mt: 1, width: 'auto' }} component={Paper} variant="outlined">
			<Table stickyHeader sx={{ minWidth: 1400 }} aria-label="order table">
				<TableHead>
					<TableRow>
						<TableCell>{t('offerRequest:label_createdAt')}</TableCell>
						<TableCell align="left">{t('offerRequest:desiredExecutionAt')}</TableCell>
						<TableCell align="left">{t('offerRequest:label_status')}</TableCell>
						<TableCell align="left">{t('offerRequest:label_title')}</TableCell>
						<TableCell align="left">{t('offerRequest:label_creator')}</TableCell>
						<TableCell align="left">{t('offerRequest:label_assignee')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{list.data?.map((offerRequest) => (
						<TableRow
							sx={{
								cursor: 'pointer',
								'&:hover': { backgroundColor: theme?.palette?.secondaryShades4p }
							}}
							onClick={() => handleCellClick(offerRequest.id)}
							key={offerRequest.id}
						>
							<TableCell>
								<Typography variant="body2">
									{dateTime(offerRequest.createdAt).toFormat('yyyy-LL-dd')}
								</Typography>
								<Typography variant="caption">
									{dateTime(offerRequest.createdAt).toFormat('T')}
								</Typography>
							</TableCell>
							<TableCell align="left">
								{offerRequest.desiredExecutionAt
									? dateTime(offerRequest.desiredExecutionAt).toFormat('dd.MM.yyyy')
									: t('offerRequest:placeholder_desiredExecutionAt')}
							</TableCell>
							<TableCell align="left">
								<Chip
									size="small"
									sx={{
										borderRadius: '4px',
										minWidth: theme.spacing(11),
										background: mapStatusToColor[offerRequest?.status]?.bg || undefined,
										color: mapStatusToColor[offerRequest?.status]?.text || undefined
									}}
									label={mapStatusToLabel[offerRequest.status]}
								/>
							</TableCell>
							<TableCell align="left">{offerRequest.title}</TableCell>
							<TableCell align="left">
								{offerRequest?.customer?.name ?? t('offerRequest:placeholder_customer_name')}
							</TableCell>
							<TableCell align="left">
								{offerRequest?.assignee?.lastName ?? t('offerRequest:placeholder_assignee')}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
