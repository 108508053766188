import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { UpgradeButton } from '../../../components/UpgradeButton/App';

import Page from '../../../mui-custom/Page/Page';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
	Typography,
	TextField,
	Stack,
	Dialog,
	DialogContent,
	DialogTitle,
	Alert,
	AlertTitle,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';
import SecondaryButton, { ButtonColor } from '../../../mui-custom/Button/SecondaryButton';
import ContentCard from '../../../mui-custom/ContentCard/ContentCard';
import { DefinitionListNormal } from '../../../stories/DefinitionList.stories';
import { ListedCard } from '../../../mui-custom/ListedCard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ActionBar from '../../../mui-custom/ActionBar/ActionBar';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../reducers';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { supportedCountryCodes } from '../../../components/CountrySelect';
import * as countries from 'i18n-iso-countries';
import * as actions from './actions';
import { openPortalSession } from '../../../components/UpgradeButton/actions';
import { OwnerExclusivePermission } from '../../../../server/permissions/permission';
import { usePermission } from '../../acl/permissions';
import { AccountSpecificFeature, useAccountSpecificFeature } from '../../acl/features';
import { PricingDetails, getPlanObject } from '../../../components/PricingDetails';
import { getPrettyPlanName, getTrialEndDate } from './utils';
import { ChargebeeSubscriptionStatus } from '../../../../server/chargebee/chargebee.enums';
import { api } from '../../../lib/api';
import { OrderStatus } from '../../../data/types/orderStatus';
import { getAvailableOrderCredits } from '../../orders/helper';
import { useCheckCredits } from '../../orders/single/InitialActions';
import { PrimaryButton } from '../../../mui-custom/Button';
import CheckIcon from '@mui/icons-material/Check';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export const AdminAccountPage = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { account } = useSelector((state: State) => state.admin.account);
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);

	useEffect(() => {
		dispatch(actions.getActiveSubscription());
		dispatch(actions.getActiveRestrictions());
	}, [account, actions]);

	return (
		<Page headline={t('general:subscription_&_payment_details')}>
			<PricingDetails />
			{hasPricingModelV2 && account?.deactivatedAt === null && <FreeAccountCard />}
			<PaymentDetails />
		</Page>
	);
};

export const FreeAccountCard = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [orderNumber, setOrderNumber] = useState(0);
	const onClose = () => setIsFreeSubscribeOpen(false);
	const contactSupport = () => {
		window.location.href = `mailto:${t('general:support_email')}`;
	};
	const [isFreeSubscribeOpen, setIsFreeSubscribeOpen] = useState(false);
	const openFreeSubscribe = async () => {
		const ordersRes = await Promise.all([
			api.get('/api/v1/orders', { params: { status: OrderStatus.OPEN, limit: 100 } }),
			api.get('/api/v1/orders', { params: { status: OrderStatus.IN_PROGRESS, limit: 100 } })
		]);

		const orders = ordersRes[0]?.data
			.concat(ordersRes[1]?.data)
			.filter((order) => order?.casaviTicketId);

		const orderNumber = orders?.length;
		setOrderNumber(orderNumber);
		setIsFreeSubscribeOpen(true);
	};
	const confirmFreeSubscribe = () => {
		dispatch(actions.deactivateAccount());
		onClose();
	};

	const listItemCheck = [
		t('general:use_free_subscription_list_item_check_1'),
		t('general:use_free_subscription_list_item_check_2')
	];
	const listItemUncheck = [
		t('general:use_free_subscription_list_item_uncheck_1'),
		t('general:use_free_subscription_list_item_uncheck_2'),
		t('general:use_free_subscription_list_item_uncheck_3'),
		t('general:use_free_subscription_list_item_uncheck_4'),
		t('general:use_free_subscription_list_item_uncheck_5')
	];

	return (
		<>
			<Alert
				severity="info"
				icon={false}
				action={
					<PrimaryButton size="small" onClick={openFreeSubscribe} variant="text">
						{t('general:use_free_subscription_checkbox_label')}
					</PrimaryButton>
				}
			>
				<AlertTitle>{t('general:use_free_subscription')}</AlertTitle>
				<Typography variant="body2">
					<Typography
						dangerouslySetInnerHTML={{
							__html: t('general:use_free_subscription_description')
						}}
					/>
				</Typography>
			</Alert>
			<Dialog open={isFreeSubscribeOpen} onClose={onClose} maxWidth="md">
				<DialogTitle>{t('general:use_free_subscription_description_confirm_title')}</DialogTitle>
				<DialogContent dividers>
					<>
						<Typography sx={{ mb: 2 }}>
							{t('general:use_free_subscription_description_confirm_content_1')}
						</Typography>
						<Typography>
							{t('general:use_free_subscription_description_confirm_content_2', { orderNumber })}
						</Typography>
					</>
				</DialogContent>
				<DialogContent dividers>
					<List dense={true}>
						{listItemCheck.map((item) => {
							return (
								<ListItem key={item}>
									<ListItemIcon sx={{ minWidth: '35px' }}>
										<CheckIcon />
									</ListItemIcon>
									<ListItemText primary={item} />
								</ListItem>
							);
						})}
						{listItemUncheck.map((item) => {
							return (
								<ListItem key={item}>
									<ListItemIcon sx={{ minWidth: '35px' }}>
										<CancelOutlinedIcon />
									</ListItemIcon>
									<ListItemText secondary={item} />
								</ListItem>
							);
						})}
					</List>
				</DialogContent>
				<ActionBar
					primary={{
						children: t('general:use_free_subscription_description_confirm_confirm'),
						onClick: confirmFreeSubscribe,
						id: 'confirm-free-subscribe'
					}}
					secondary={{
						children: t('general:use_free_subscription_description_confirm_contact_support'),
						onClick: contactSupport
					}}
					alternate={{
						children: t('general:use_free_subscription_description_confirm_back'),
						onClick: onClose
					}}
				/>
			</Dialog>
		</>
	);
};

export const PaymentMethodMissingAlert = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const onClickHandler = () => dispatch(openPortalSession(true));
	return (
		<MuiAlert
			severity="warning"
			action={
				<SecondaryButton onClick={onClickHandler}>{`${t(
					'admin:account_pricing_payment_method_alert_button'
				)}`}</SecondaryButton>
			}
		>
			<MuiAlertTitle>{`${t('admin:account_pricing_payment_method_alert_title')}`}</MuiAlertTitle>
			{`${t('admin:account_pricing_payment_method_missing_content')}`}
		</MuiAlert>
	);
};

/* this part is facade for derivate Alerts */
export const AccountAlert = (props: { [x: string]: unknown }) =>
	useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2) ? (
		<AccountAlertV2 {...props} />
	) : (
		<AccountAlertV1 {...props} />
	);

/* this is renamed to AccountAlertV1 from org. old AccountAlert, otherwise unchanged */
export const AccountAlertV1 = () => {
	const { account, activeRestrictions } = useSelector((state: State) => state.admin.account);
	const { t } = useTranslation();
	const plan = getPlanObject(account?.plan || null, t);
	return (
		<MuiAlert severity="warning">
			<MuiAlertTitle>{`${t('admin:account_pricing_alert_title')} ${
				plan?.currentText
			}`}</MuiAlertTitle>
			{`${t('sysadmin:account_users')} ${activeRestrictions?.activeUsers}/${
				account?.maxActiveUsers
			}`}
			<br />
			{`${t('sysadmin:account_customers')} ${activeRestrictions?.activeCustomers}/${
				account?.maxActiveCustomers
			}`}
			{plan && (
				<UpgradeButton
					buttonText={`${t('admin:licenses_upgrade_to_button')} ${plan?.nextText}`}
					secondary={true}
				/>
			)}
		</MuiAlert>
	);
};

export const AccountAlertV2 = () => {
	const { user } = useSelector((state: State) => state.auth);
	const { account, activeSubscription } = useSelector((state: State) => state.admin.account);
	const { t } = useTranslation();
	const plan = account?.plan || activeSubscription?.plan_id;

	const { isOutOfCredits } = useCheckCredits();

	if (activeSubscription?.status === ChargebeeSubscriptionStatus.IN_TRIAL) {
		return <AccountAlertTrial />;
	}
	if (new Date(user?.activePlanUntil) < new Date() || user?.activePlanUntil === null) return null;
	return plan ? (
		<MuiAlert severity={!isOutOfCredits ? 'success' : 'warning'} icon={false}>
			{isOutOfCredits ? (
				t('admin:account_pricing_order_credits_exceeded')
			) : (
				<>
					<MuiAlertTitle>{`${t('admin:account_pricing_alert_title')} ${getPrettyPlanName(
						plan
					)}`}</MuiAlertTitle>
					{`${t('admin:account_pricing_order_credits')} ${getAvailableOrderCredits(account)}`}
				</>
			)}
		</MuiAlert>
	) : null;
};

export const AccountAlertTrial = () => {
	const { activeSubscription } = useSelector((state: State) => state.admin.account);
	const trialEndDate = getTrialEndDate(activeSubscription?.trial_end) || null;
	const { t } = useTranslation();
	return (
		<MuiAlert severity="info">
			<MuiAlertTitle>{`${t('admin:account_pricing_alert_trial_title')}`}</MuiAlertTitle>
			{`${t('admin:account_pricing_alert_trial_content', { trialEndDate })}`}
		</MuiAlert>
	);
};

/** this is also a hidden any should be removed, refactored later */
export const AccountDetails = (props) => {
	const {
		onDetailsUpdate,
		handleNameChange,
		handleStreetChange,
		handlePostalChange,
		newAccountInfo,
		t,
		handleLocalityChange,
		handleCountryChange,
		onSubmit,
		handleCancelClick,
		open
	} = props;
	const address = newAccountInfo?.address;

	const companyDataItems = [
		{
			key: t('general:company_name'),
			value: newAccountInfo?.name
		},
		{
			key: t('general:street'),
			value: address?.streetAddress
		},
		{
			key: t('general:locality'),
			value: `${address?.postalCode} ${address?.locality}${address?.country ? ',' : ''} ${
				address?.country
			}`
		}
	];
	const getAvailableCountries = () => {
		const countryNames = countries.getNames('de');
		return (
			Object.keys(countryNames)
				// eslint-disable-next-line @typescript-eslint/prefer-includes
				.filter((value) => supportedCountryCodes.indexOf(value) !== -1)
				.map((value) => ({
					value,
					label: countryNames[value]
				}))
		);
	};
	const optionsForCountryDropDown = [{ value: null, label: '' }, ...getAvailableCountries()];
	return (
		<ContentCard headline={t('admin:account_details_header')} text={''}>
			<DefinitionListNormal items={companyDataItems} />
			<ActionBar
				primary={{ children: t('general:update'), onClick: onDetailsUpdate }}
				primaryAsSecondary
			/>
			<MuiDialog open={open} onClose={onDetailsUpdate}>
				<MuiDialogTitle>{t('admin:account_details_header')}</MuiDialogTitle>
				<MuiDialogContent dividers>
					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									size="medium"
									label={t('general:company_name')}
									fullWidth
									onChange={handleNameChange}
									value={newAccountInfo?.name || ''}
									variant="outlined"
									margin="normal"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									size="medium"
									label={t('admin:account_details_street')}
									fullWidth
									onChange={handleStreetChange}
									value={address?.streetAddress || ''}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'space-between'
								}}
							>
								<TextField
									size="medium"
									label={t('admin:account_details_postal_code')}
									sx={{ width: '10ch' }}
									onChange={handlePostalChange}
									value={address?.postalCode || ''}
								/>
								<TextField
									size="medium"
									label={t('admin:account_details_locality')}
									sx={{ width: '50ch' }}
									onChange={handleLocalityChange}
									value={address?.locality || ''}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth>
									<InputLabel>{t('admin:account_details_country')}</InputLabel>
									<Select
										size="medium"
										onChange={handleCountryChange}
										displayEmpty
										label="label"
										value={address?.country}
									>
										{optionsForCountryDropDown.map((option) => (
											<MenuItem key={option.label} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
				</MuiDialogContent>
				<MuiDialogActions>
					<ActionBar
						primary={{ children: t('general:save'), onClick: onSubmit }}
						secondary={{
							children: t('general:cta_cancel'),
							color: ButtonColor.Creative,
							onClick: handleCancelClick
						}}
					/>
				</MuiDialogActions>
			</MuiDialog>
		</ContentCard>
	);
};

export const PaymentDetails = () => {
	const { t } = useTranslation();
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);
	const onMethodChange = () => dispatch(openPortalSession(hasPricingModelV2));

	const { paymentSources } = useSelector((state: State) => state.admin.account);
	const hasPermission = usePermission(OwnerExclusivePermission.MODIFY_PAYMENT_SOURCE);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(actions.loadPaymentSources());
	}, []);

	return (
		hasPermission && (
			<ContentCard headline={t('admin:payment_details')} text={''}>
				{paymentSources?.length > 0 ? (
					paymentSources.map((paymentSource) => (
						<ListedCard
							key={paymentSource.id}
							thumbnail={
								paymentSource.type === 'card'
									? `./../../static/images/cn-${paymentSource.card.brand}.svg`
									: './../../static/images/cn-sepa.svg'
							}
							headline={
								paymentSource.type === 'card' ? t('admin:credit_card') : t('admin:direct_debit')
							}
							text={
								<>
									{paymentSource.type === 'card' && (
										<Stack>
											<Typography color="textSecondary">
												{t('admin:masked_number_label')}:
											</Typography>
											<Typography color="textPrimary">
												{paymentSource.card.masked_number}
											</Typography>
										</Stack>
									)}
									{paymentSource.type === 'direct_debit' && (
										<Stack>
											<Stack direction="row" gap={1}>
												<Typography color="textSecondary">{t('admin:account_owner')}:</Typography>
												<Typography color="textPrimary">
													{paymentSource.bank_account.name_on_account}
												</Typography>
											</Stack>
											<Stack direction="row" gap={1}>
												<Typography color="textSecondary">
													{t('admin:masked_number_label')}:
												</Typography>
												<Typography color="textPrimary">
													**** **** **** {paymentSource.bank_account.last4}
												</Typography>
											</Stack>
											{paymentSource.bank_account.bank_name && (
												<Stack direction="row" gap={1}>
													<Typography color="textSecondary">
														{t('admin:bank_name_label')}:
													</Typography>
													<Typography color="textPrimary">
														{paymentSource.bank_account.bank_name}
													</Typography>
												</Stack>
											)}
										</Stack>
									)}
								</>
							}
							icon={<ChevronRightIcon />}
							onClick={onMethodChange}
						></ListedCard>
					))
				) : (
					<ListedCard
						headline={t('admin:no_payment_details')}
						text={t('admin:payment_details_warning')}
						icon={<ChevronRightIcon />}
						onClick={onMethodChange}
					/>
				)}
				<ActionBar
					primary={{
						children: t('admin:change_payment_method'),
						onClick: onMethodChange
					}}
					primaryAsSecondary
				/>
			</ContentCard>
		)
	);
};
